:root {
  --main-color: #0089c3;
  --white: #fff; }

.--btn-primary {
  background-color: var(--main-color) !important;
  color: var(--white) !important;
  border-color: var(--main-color) !important; }

.--bg-primary {
  background-color: var(--main-color) !important;
  color: var(--white) !important;
  border-color: var(--main-color) !important; }

.--btn-ouline-primary {
  color: var(--main-color) !important;
  border-color: var(--main-color) !important; }
  .--btn-ouline-primary:hover {
    color: var(--white) !important;
    background-color: var(--main-color) !important; }

.--border-color-after:before {
  border-color: var(--main-color) transparent transparent transparent !important; }

.--border-2px-primary {
  border: 2px solid var(--main-color) !important; }

.--border-1px-primary {
  border: 1px solid var(--main-color) !important; }

.--border-1-bottom {
  border-bottom: 1px solid var(--main-color) !important; }

.--border-3-top {
  border-top: 3px solid var(--main-color) !important; }

.--text-primary {
  color: var(--main-color) !important; }

.--active-bg-primary {
  background-color: var(--main-color) !important; }

.--hover-text-primary:hover {
  color: var(--main-color) !important; }

.--tab-active-primary.active {
  background-color: var(--main-color) !important;
  color: var(--white) !important; }

.--tab-active-text-primary.active {
  color: var(--main-color) !important;
  border-bottom-color: var(--main-color) !important; }

.--hover-bg-item:hover {
  background-color: var(--main-color) !important;
  color: var(--white) !important; }

.-hasEvent {
  cursor: pointer; }
  .-hasEvent:hover {
    color: var(--main-color) !important;
    opacity: 1; }

.checkBoxCustom .selection-cell-header {
  background-color: var(--main-color) !important;
  border: 1px solid var(--main-color) !important; }

.--active-primary-color {
  color: var(--main-color) !important; }
  .--active-primary-color:before {
    background: var(--main-color) !important;
    color: var(--white) !important; }
  .--active-primary-color:after {
    background: var(--main-color) !important;
    color: var(--white) !important; }

.--current-before-primary .item-title {
  color: var(--main-color) !important; }

.--current-before-primary .dotItem:before {
  border: 8px solid var(--main-color) !important;
  background: var(--main-color) !important; }

.--current-before-primary.active .item-title {
  background-color: var(--main-color) !important;
  color: var(--white) !important; }

.--current-before-primary:hover .item-title {
  background-color: var(--main-color) !important;
  color: var(--white) !important; }

.--progress-bar-primary .progress-bar {
  background-color: var(--main-color) !important; }

.dropdown.active:not(.show) {
  position: relative !important; }
  .dropdown.active:not(.show):before {
    border-color: var(--main-color) transparent transparent transparent !important; }

.pagination .page-item.active .page-link {
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important; }

.pagination .page-link {
  color: var(--main-color) !important; }

.pagination .page-item.active .page-link {
  color: #fff !important; }

.pagination .page-item.disabled {
  opacity: 0.8; }
  .pagination .page-item.disabled .page-link {
    color: #6c757d !important; }

.showEffectMenu:after {
  background-color: var(--main-color) !important;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
  animation-name: blink;
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

@keyframes glowing {
  0% {
    box-shadow: 0 0 5px var(--main-color); }
  50% {
    box-shadow: 0 0 20px var(--main-color); }
  100% {
    box-shadow: 0 0 5px var(--main-color); } }

.glowing-effect {
  animation: glowing 1300ms infinite; }

.shadown-effect {
  box-shadow: 0 0 20px var(--main-color); }

.overide-effect {
  box-shadow: none !important; }

.dropdownReset__button {
  color: var(--main-color);
  border-radius: 50% !important;
  padding: 0 !important;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: var(--main-color) !important;
  cursor: pointer;
  transition: 0.5s ease-in-out; }
  .dropdownReset__button i {
    color: var(--main-color); }

.dropdownResetBody {
  top: 30px !important;
  padding: 0 !important; }
  .dropdownResetBody .dropdown-item {
    padding: 0 !important; }
    .dropdownResetBody .dropdown-item:hover {
      background: none !important; }
    .dropdownResetBody .dropdown-item a,
    .dropdownResetBody .dropdown-item span {
      padding: 10px;
      color: #212529;
      width: 100%;
      display: block; }
      .dropdownResetBody .dropdown-item a:hover,
      .dropdownResetBody .dropdown-item span:hover {
        color: var(--main-color); }

.--hover-outline-primary {
  transition: ease-in-out 0.5s; }
  .--hover-outline-primary:hover {
    background: var(--main-color) !important;
    color: var(--white) !important;
    background-color: var(--main-color) !important; }
    .--hover-outline-primary:hover span {
      color: var(--white); }
    .--hover-outline-primary:hover > i {
      color: var(--white) !important; }
